const hostname = "https://webapp.zaidexceldesign.com";
module.exports=
{
    appUrl:`https://webclient.zaidexceldesign.com`,
    date_time_format:{
        date:"DD-MM-YYYY",
        time:"HH:mm:ss"
    },
    urls:{
        baseurl:`${hostname}/public`,
        dir_url:`${hostname}/public`,
        backend_url : `${hostname}/backend/client-api`
    },
    header_key:{
        auth:"authorization",
    },
    userDataStorage:{
        key:"zaidclient",
    },
    role:{
        admin_key:"admin",
        employee_key:"Employees",
        client_key:"Client"
    },
    defaultUserPic:`${""}/default/DefaultImage.png`,
    userFieldCommon:{
        id:"id",
        first_name:"first_name",
        last_name:"last_name",
        gender:"gender",
        fullname:"fullname",
        email:"email",
        profile_pic:"profile_pic",
        token:"token",
        role:"user_roles"
    },
    rsmessage:{
        success:"Success!",
        failed:"Something went wrong",
        token_expired:"Timed Out"
    },
    validationMsg:{
        invalidPhone:"10 digits phone no.",
        dateAfter:"Invalid date range"
    }
}