import React, { useState, useEffect } from 'react'
import { GetFileDetails, GetPlotValidation } from '../../../config/api';
import { callApi } from '../../../services/ApiService';
import { AuthHeader, toastError } from '../../../services/CommonFunction';
import { useParams } from 'react-router';
import { DisplayLoader } from '../../../UtilsComponent/DisplayLoader';
import { GetDate } from '../../../services/DateAndTimeService';
import { appUrl, urls } from '../../../config/constant';
import DownloadLink from "react-download-link";


const PlotValidationCertificate = () => {

  const params = useParams();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  const sanitizeDate = (date) => {
    return date!=null ? GetDate(date, "DD-MM-YYYY") : "-"
  }


  

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await callApi(GetPlotValidation.method, GetPlotValidation.url, params?.id, null, AuthHeader());

      if (!res?.data?.error) {
        setLoading(false);

        const data1 = res?.data?.data1;

        const data2 = res?.data?.data2;

        if (Array.isArray(data2) && data2?.length > 0) {
          if (Array.isArray(data1) && data1.length > 0) {
            
            // setFormDisabled(data1.every((d) => (d?.inward_is_save == 1 && d?.outward_is_save == 1)) ? 1 : 0)

            data1.forEach((data1_element, data1_index) => {
              data1_element.inward_no = data1_element.inward_is_save == 1 ? data1_element.inward_no : (data2[data1_index]?.is_rejected == 1 ? (data1_element.inward_no ? data1_element.inward_no : data2[data1_index]?.inward_no) : data1_element.inward_no);
              data1_element.inward_date = data1_element.inward_is_save == 1 ? sanitizeDate(data1_element.inward_date) : (data2[data1_index]?.is_rejected == 1 ? (data1_element.inward_date ? sanitizeDate(data1_element.inward_date) : sanitizeDate(data2[data1_index]?.inward_date)) : sanitizeDate(data1_element.inward_date));
              data1_element.inward_assign_to = data1_element.inward_is_save == 1 ? data1_element.inward_assign_to : (data2[data1_index]?.is_rejected == 1 ? (data1_element.inward_assign_to ? data1_element.inward_assign_to : data2[data1_index]?.inward_assign_to) : data1_element.inward_assign_to);
              data1_element.outward_no = data1_element.outward_is_save == 1 ? data1_element.outward_no : (data2[data1_index]?.is_rejected == 1 ? (data1_element.outward_no ? data1_element.outward_no : data2[data1_index]?.outward_no) : data1_element.outward_no);
              data1_element.outward_date = data1_element.outward_is_save == 1 ? sanitizeDate(data1_element.outward_date) : (data2[data1_index]?.is_rejected == 1 ? (data1_element.outward_date ? sanitizeDate(data1_element.outward_date) : sanitizeDate(data2[data1_index]?.outward_date)) : sanitizeDate(data1_element.outward_date));
              data1_element.outward_assign_to = data1_element.outward_is_save == 1 ? data1_element.outward_assign_to == 1 : (data2[data1_index]?.is_rejected == 1 ? (data1_element.outward_assign_to ? data1_element.outward_assign_to : data2[data1_index]?.outward_assign_to) : data1_element.outward_assign_to);
              data1_element.documents = data1_element.outward_is_save == 1 ? data1_element.documents : (data2[data1_index]?.is_rejected == 1 ? (data1_element.documents ? data1_element.documents : data2[data1_index]?.documents) : data1_element.documents);
              data1_element.document_upload_date = data1_element.outward_is_save == 1 ? sanitizeDate(data1_element.document_upload_date) : (data2[data1_index]?.is_rejected == 1 ? (data1_element.document_upload_date ? sanitizeDate(data1_element.document_upload_date) : sanitizeDate(data2[data1_index]?.document_upload_date)) : sanitizeDate(data1_element.document_upload_date));

            });

            setData(data1);
          }
        }
        else {

          data1.forEach((data1_element, data1_index) => {
            data1_element.inward_date = sanitizeDate(data1_element.inward_date);
            data1_element.outward_date = sanitizeDate(data1_element.outward_date);
            data1_element.document_upload_date = sanitizeDate(data1_element.document_upload_date);
          });

          setData(data1)
        }

      }
      else {
        setLoading(false);

        toastError(res?.data?.message);
      }

    }
    catch (err) {
      setLoading(false);

      console.log(err);
      toastError(err?.res?.data?.message);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const myStyle = {
    width: "10px"
  }

  const downloadFile = (
    filePath,
    fileName = filePath,
  ) => {
    
    fetch('https://webclient.zaidexceldesign.com:3001/api/'+filePath, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
    }


  return (
    <div>
      <div className="content_box_header">
        <h2>Plot validation certificate</h2>
      </div>
      <div className="content_box_body">

    {!loading ? 

      <div>
       
        {data.length>0 
        ?
          <table className='table'>
          <thead>
            <tr>
              <th style={myStyle}>No.</th>
              <th style={myStyle}>Stage</th>
              <th style={myStyle}>Inward Date</th>
              <th style={myStyle}>Outward Date</th>
              <th style={myStyle}>Upload Date</th>
              <th style={myStyle}>File</th>
            </tr>
          </thead>
          
          <tbody>
          {data.map((value,index)=>(
            <React.Fragment>

            {value?.inward_is_save==1?
            <tr>
              <td width={"10%"}>{index+1}</td>
              <td>{value?.type_name}</td>
              <td>{value?.inward_no ?  value?.inward_date : ""}</td>
              <td>{value?.outward_is_save==1 ? value?.outward_no ?   value?.outward_date  : "" : ""}</td>
              <td>{(value?.documents?.lenght>0  || value?.documents!=null ) ?  value?.document_upload_date : ""}</td>
              <td>{value?.documents?.lenght>0  || value?.documents!=null  ? <button onClick={() => downloadFile(value?.documents)}><img src={`${appUrl}/assets/svgs/down_arrow.svg`}/></button> : ""}</td>
       
              {/* <td>{value?.documents?.lenght>0  || value?.documents!=null  ? <a href={`${urls.baseurl}/${value?.documents}`} download><img src={`${appUrl}/assets/svgs/down_arrow.svg`}/></a> : ""}</td> */}
            </tr> 
            :""}
            </React.Fragment>
          ))}
          </tbody>
          <tfoot></tfoot>

       
        </table>
        :
        ""}
      </div>
      :
      <DisplayLoader value={false}/>}
      </div>
    </div>
  )
}

export default PlotValidationCertificate