import React,{useState,useEffect} from 'react'
import { GetFileDetails, GetOwnerPortal, GetPlotValidation, GetRoadDeposit } from '../../../config/api';
import { callApi } from '../../../services/ApiService';
import { AuthHeader, toastError } from '../../../services/CommonFunction';
import { useParams } from 'react-router';
import { DisplayLoader } from '../../../UtilsComponent/DisplayLoader';
import { GetDate } from '../../../services/DateAndTimeService';
import { urls ,appUrl} from '../../../config/constant';


const Road_Deposit_Security_Fees = () => {

  const params = useParams();

  const [loading,setLoading] = useState(false);
  const [data,setData] = useState([]);

  const defaultIsSave = { save: 0, send_msg: 0, next: 0 };

  const validJson = (jsonString) => {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  const sanitizeDate = (date1) => {
    try {
      return date1 ? GetDate(date1, "DD-MM-YYYY") : "";
    }
    catch (err) {
      console.log(err);
      return "";
    }
  }

  const fetchData =async () =>{
    try
    {
      setLoading(true);

      const res = await callApi(GetRoadDeposit.method,GetRoadDeposit.url,params?.id,null,AuthHeader());
      
      if(!res?.data?.error)
      {
        const finalData = [];
        const payload = res?.data?.data;
        
        setLoading(false);

        if (Array.isArray(payload)) 
        {
          setData(payload);
        }

      }
      else
      {
        setLoading(false);
        toastError(res?.data?.message);
      }

    }
    catch(err)
    {
      setLoading(false);
      console.log(err);
      toastError(err?.res?.data?.message);
    }
  }

  useEffect(()=>{
    fetchData();
  },[]);

  const myStyle = {
    width : "20%"
  }

  
  const downloadFile = (
    filePath,
    fileName = filePath,
  ) => {
    
    fetch('https://webclient.zaidexceldesign.com:3001/api/'+filePath, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
    }


  return (
    <div>
    <div className="content_box_header">
        <h2>Road Deposit/Scrunity Fees</h2>
    </div>
    <div className="content_box_body">
      {!loading ? 
        <table className='table'>
          <thead>
            <th style={myStyle}>No.</th>
            <th style={myStyle}>Description	</th>
            <th style={myStyle}>Amount	</th>
            <th style={myStyle}>Date	</th>
            <th>File	</th>
          </thead>
          <tbody>

            {data.map((value,index)=>(
              <tr>
                <td>{(index+1)}</td>
                <td>{value?.description}</td>
                <td>{value?.amount}</td>
                <td>{value?.document_upload_date ? GetDate(value?.document_upload_date,"DD-MM-YYYY"):""}</td>
                <td>{value?.documents ?   <button onClick={() => downloadFile(value?.documents)}><img src={`${appUrl}/assets/svgs/down_arrow.svg`}/></button> : ""}</td>
              </tr>
            ))}

            
          </tbody>
          <tfoot></tfoot>
        </table>
        :
        <DisplayLoader value={false}/>
            }
    </div>
</div>
  )
}

export default Road_Deposit_Security_Fees