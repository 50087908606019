const _file_url = "Files";
const _project_url = "Projects";
module.exports=
{
    LoginRoute:{
        preview:"login"
    },
    FileRoutes:{
        previewFile:`${_file_url}`,
        file_details:`${_file_url}/details`
    },
    ProjectRoutes:{
        previewProject:`${_project_url}`,
        project_details:`${_project_url}/details`
    },
    LogoutRoute:{
        preview:"https://www.zaidexceldesign.com",
    }
}