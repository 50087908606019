import React,{useState,useEffect} from 'react'
import { GetApprovedPlan, GetAssistantJuniorEngineer, GetCommissioner, GetDeputyCommissioner, GetWorkFree,GetDeputyEngineer, GetDocumentUpload, GetEnagarPortal, GetFileDetails, GetPlotValidation, GetZonalOfficer } from '../../../config/api';
import { callApi } from '../../../services/ApiService';
import { AuthHeader, toastError } from '../../../services/CommonFunction';
import { useParams } from 'react-router';
import { DisplayLoader } from '../../../UtilsComponent/DisplayLoader';
import { GetDate } from '../../../services/DateAndTimeService';
import { urls,appUrl } from '../../../config/constant';




const Approved_plan = (props) => {

  const params = useParams();

  const [loading,setLoading] = useState(false);
  const [data,setData] = useState([]);

  const fetchData =async () =>{
    try
    {
      setLoading(true);
      const res = await callApi(GetApprovedPlan.method,GetApprovedPlan.url,params?.id,null,AuthHeader());

      if(!res?.data?.error)
      {
        setLoading(false);

        const payload = res?.data?.data;

        if(Array.isArray(payload)){
          const rows = []
          payload.forEach((e,i)=>{
            rows.push({
              id:e?.id,
              mst_file_id : e?.mst_file_id,
              mst_document_id : e?.mst_document_id,
              document_name : e?.document_name,
              show_website : e?.show_website,
              document_path : e?.document_path,
            });
          })
          setData(rows);
        }

      }
      else
      {
        setLoading(false);
        toastError(res?.data?.message);
      }

    }
    catch(err)
    {
      setLoading(false);

      console.log(err);
      toastError(err?.res?.data?.message);
    }
  }

  const fetchWorkFee =async () =>{
    try
    {
      setLoading(true);
      const res = await callApi(GetWorkFree.method,GetWorkFree.url,params?.id,null,AuthHeader());

      if(!res?.data?.error)
      {
        setLoading(false);
        const payload = res?.data?.data;

        if(payload[0]?.fee_status===1){
          props.onUpdateStage(16);
        }else{
          fetchData();
        }
        // setData({fee_amount:payload[0]?.fee_amount? payload[0]?.fee_amount:"",fee_status:payload[0]?.fee_status?(payload[0]?.fee_status).toString():""})
      }
      else
      {
        setLoading(false);
        toastError(res?.data?.message);
      }

    }
    catch(err)
    {
      setLoading(false);

      console.log(err);
      toastError(err?.res?.data?.message);
    }
  }


  useEffect(()=>{
    fetchWorkFee();
  },[]);


    const downloadFile = (
    filePath,
    fileName = filePath,
  ) => {
    
    fetch('https://webclient.zaidexceldesign.com:3001/api/'+filePath, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
   
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
    }

  return (
    <div>
        <div className="content_box_header">
            <h2>Approved Plan</h2>
        </div>
        <div className="content_box_body">
        {!loading ? 
            <table className='table'>
              <thead>
                <tr>
                  <td style={{width:"5%"}}>No.</td>
                  <td style={{width:"30%"}}>Document name	</td>
                  <td>File</td>
                </tr>
              </thead>
              <tbody>
                {data.map((value,index)=>(
                  <tr>
                    <td>({index+1})</td>
                    <td>{value?.document_name}</td>
                    <td>{value?.document_path ? 
                  <button onClick={() => downloadFile(value?.document_path)}><img src={`${appUrl}/assets/svgs/down_arrow.svg`}/></button> : ""}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot></tfoot>
            </table>  
          :
          <DisplayLoader value={false}/>}
        </div>
    </div>
  )
}

export default Approved_plan