import React from 'react'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Login from '../components/Page/Login';
import Details from '../components/Files/Details';
import { FileRoutes, LoginRoute, ProjectRoutes } from '../config/RouteConfig';
import Wrapper from '../containers/Wrapper';
import FileList from '../components/Files/FileList';
import RequireAuth from '../AuthComponent/RequireAuth';
import { customerKey } from '../config/RoleConfig';
import Home from '../components/Page/Home';
import ProjectList from '../components/Projects/ProjectList';

import ProjectDetails from '../components/Projects/Details';


const ClientApplication = () => {
    process.env.TZ = "Asia/Culcutta";
    return (
        <>
            <Routes>
                
                <Route path="*" element={<h1>Not found</h1>} />

                <Route path="/" element={<Login />}>
                    <Route path={`/${LoginRoute.preview}`} element={<Login />} />
                </Route>

                <Route element={<RequireAuth accessible={[customerKey]}/>}>
                    <Route path={`/${FileRoutes.previewFile}`} element={<Wrapper children={<Home children={<FileList/>}/>}/>}/>
                </Route>

                <Route element={<RequireAuth accessible={[customerKey]}/>}>
                    <Route path={`/${FileRoutes.file_details}/:id`} element={<Wrapper children={<Details/>}/>}/>
                </Route>

                <Route element={<RequireAuth accessible={[customerKey]}/>}>
                    <Route path={`/${ProjectRoutes.previewProject}`} element={<Wrapper children={<Home children={<ProjectList/>}/>}/>}/>
                </Route>

                <Route element={<RequireAuth accessible={[customerKey]}/>}>
                    <Route path={`/${ProjectRoutes.project_details}/:id`} element={<Wrapper children={<ProjectDetails/>}/>}/>
                </Route>
            
            </Routes>
        </>
    )
}

export default ClientApplication