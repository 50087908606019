import React,{useEffect, useState} from 'react'
import {useForm} from "react-hook-form";
import { LoginApi } from '../../config/api';
import { callApi } from '../../services/ApiService';
import { defaultHeader, toastError, toastSuccess } from '../../services/CommonFunction';
import { displayError, formclass, isEmail } from '../../services/FormServices';
import {Toaster,toast}  from 'react-hot-toast';
import { getToken, getUserSession, saveUserSession } from '../../services/AuthService';
import {useNavigate} from 'react-router-dom';
import { FileRoutes } from '../../config/RouteConfig';
import { DisplayLoader } from '../../UtilsComponent/DisplayLoader';
import { getUserFullName } from '../../services/UserData';
import { appUrl } from '../../config/constant';
import { mailPattern } from '../../services/Pattern';
import { ReactSVG } from 'react-svg'


const Login = () => {

  const [ loading, setLoading ] = useState(false);  
  const {register,handleSubmit,formState:{errors},getValues,trigger} = useForm({
    mode:"onChange"
  });

  const navigate = useNavigate();

  const onSubmit = async (data) =>{
    try
    {   

            setLoading(true);
    
            const res = await callApi(LoginApi.method,LoginApi.url,null,data,defaultHeader());
            console.log("called");
    
            if(!res?.data?.error)
            {
                setLoading(false);
                const payload = res?.data?.data;
                
                const userinfo = {
                    username: payload?.username ? payload?.username : "",
                    profile_picture:payload?.profile_picture ? payload?.profile_picture : "",
                    fullname:payload?.fullname ? payload?.fullname :"",
                    first_name:payload?.first_name ? payload?.first_name : "",
                    last_name:payload?.last_name ? payload?.last_name : "",
                    user_email: payload?.email ? payload?.email : "",
                    token : payload?.token ? payload?.token : ""
                };

                saveUserSession(userinfo);
                
                toastSuccess(res.data?.message);

                navigate(`/${FileRoutes.previewFile}`);
            }
            else
            {
                setLoading(false);
                toastError(res.data?.message)
            }
        

        

    }
    catch(err)
    {
        setLoading(false);
        toastError("Something went wrong");
    }
  }




  return (
    <React.Fragment>
        <Toaster
                position="top-right"
                reverseOrder={false}
            />

            <div className='login_page'>
            <div>
         <div class="login_row w-100">
            <div class="login_percentage">
               <div class="login_part">
                  <div class="header__logo">
                      <a href="#">
                        <img src={`${appUrl}/assets/images/logo_dark.png`} alt=""/>
                     </a>
                  </div>
                  <div class="header_message"><h1>Hi, Welcome Back!</h1></div>
                  <div class="authentication__form">
                  {!loading ? 
                     <form aria-label="Login" onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                           <label for="email">Email</label>
                           <div className="input-group">
                              <div className="input-group-prepend">
                                 <div className="input-group-text bg-transparent"><i className="fa-regular fa-user"></i></div>
                              </div>
                              <input 
                                type="email"
                                name="email"
                                id="email"
                                placeholder='Email'
                                className={formclass(errors?.email,"form-control border-left-0")}
                                {...register("email",{
                                    required:"Required",
                                    pattern:{
                                        value:mailPattern,
                                        message:"Invalid email"
                                    }
                                })} 
                                

                              />
                           </div>
                           <p className="text-danger">{errors?.email?.message}</p>
                           {displayError(errors?.email?.message)}
                        </div>
                        <div className="form-group">
                           <label for="password">Password</label>
                           <div className="input-group">
                              <div className="input-group-prepend">
                                 <div className="input-group-text bg-transparent"><i className="fa-solid fa-lock"></i></div>
                              </div>
                              <input 
                                    type="password"
                                    name="password"
                                    id="password"
                                    placeholder="******"
                                    className={formclass(errors?.password,"form-control border-left-0")}
                                    {...register("password",{required:"Required"})} 
                               />
                           </div>
                           <p className="text-danger">{errors?.password?.message}</p>
                            {displayError(errors?.password?.message)}
                        </div>
                        <div className="form-group"><button type="submit" className="btn d-block w-100 btn__login">Login</button></div>
                     </form>
                     :<DisplayLoader value={false}/>}

                  </div>
               </div>
            </div>
            <div class="img_percentage">
               <div class="inner_img_percentage">
                  <img src={`${appUrl}/assets/svgs/zaid_excel_logo.svg`} alt=""/>
               </div>
            </div>
         </div>
      </div>
            </div>
    
         </React.Fragment>
  )
}

export default Login


