import React,{useState,useEffect} from 'react'
import { GetAssistantJuniorEngineer, GetCommissioner, GetDeputyCommissioner, GetDeputyEngineer, GetDocumentUpload, GetEnagarPortal, GetFileDetails, GetPlotValidation, GetWorkFree, GetZonalOfficer } from '../../../config/api';
import { callApi } from '../../../services/ApiService';
import { AuthHeader, toastError } from '../../../services/CommonFunction';
import { useParams } from 'react-router';
import { DisplayLoader } from '../../../UtilsComponent/DisplayLoader';

const Work_fee = () => {

  const params = useParams();

  const [loading,setLoading] = useState(false);
  const [data,setData] = useState();

  const fetchData =async () =>{
    try
    {
      setLoading(true);
      const res = await callApi(GetWorkFree.method,GetWorkFree.url,params?.id,null,AuthHeader());

      if(!res?.data?.error)
      {
        setLoading(false);
        const payload = res?.data?.data;
        setData({fee_amount:payload[0]?.fee_amount? payload[0]?.fee_amount:"",fee_status:payload[0]?.fee_status?(payload[0]?.fee_status).toString():""})
      }
      else
      {
        setLoading(false);
        toastError(res?.data?.message);
      }

    }
    catch(err)
    {
      setLoading(false);

      console.log(err);
      toastError(err?.res?.data?.message);
    }
  }

  useEffect(()=>{
    fetchData();
  },[]);

  return (
    <div>
    <div className="content_box_header">
        <h2>Work Fee</h2>
    </div>
    <div className="content_box_body">
        {!loading ? 

          <table className='table'>
            <thead></thead>
            <tbody>
              <tr>
                <td style={{width:"20%"}}>Work Fee</td>
                <td>{data?.fee_amount}</td>
              </tr>
              <tr>
                <td style={{width:"20%"}}>Status</td>
                <td>{data?.fee_status==1 ? "Pending" : data?.fee_status==2 ?  "Paid" : ""}</td>
              </tr>
            </tbody>
            <tfoot></tfoot>
          </table>

        :
        <DisplayLoader value={false}/>}
    </div>
</div>
  )
}

export default Work_fee