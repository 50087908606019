import React,{useState,useEffect} from 'react'
import { GetFileDetails, GetOwnerPortal, GetPlotValidation } from '../../../config/api';
import { callApi } from '../../../services/ApiService';
import { AuthHeader, toastError } from '../../../services/CommonFunction';
import { useParams } from 'react-router';
import { DisplayLoader } from '../../../UtilsComponent/DisplayLoader';
import { GetDate } from '../../../services/DateAndTimeService';

const OwnerPortal = () => {
  const params = useParams();

  const [loading,setLoading] = useState(false);
  const [data,setData] = useState();

  const fetchData =async () =>{
    try
    {
      setLoading(true);

      const res = await callApi(GetOwnerPortal.method,GetOwnerPortal.url,params?.id,null,AuthHeader());

      if(!res?.data?.error)
      {
        
        setLoading(false);
        if(Array.isArray(res?.data?.data))
        {
          setData(res?.data?.data[0]);
        }
      }
      else
      {
        setLoading(false);
        toastError(res?.data?.message);
      }

    }
    catch(err)
    {
      setLoading(false);
      console.log(err);
      toastError(err?.res?.data?.message);
    }
  }

  useEffect(()=>{
    fetchData();
  },[]);

  const sanitizeDate = (date) => {
    return date!=null ? GetDate(date, "DD-MM-YYYY") : "-"
  }

   
  const myStyle = {
    width: "35%"
  }

  return (
    <div>
        <div className="content_box_header">
            <h2>Owner Portal</h2>
        </div>
        <div className="content_box_body">
            {!loading ? 
              <table className='table'>
                <thead></thead>
                <tbody>
                <tr>
                  <td style={myStyle}>Application Number</td>
                  <td>{data?.application_number}</td>
                </tr>
                <tr>
                  <td style={myStyle}>Application Number</td>
                  <td>{sanitizeDate(data?.upload_date)}</td>
                </tr>
                <tr>
                  <td style={myStyle}>Approved Date</td>
                  <td>{sanitizeDate(data?.approve_date)}</td>
                </tr>
                <tr>
                  <td style={myStyle}>Login ID</td>
                  <td>{data?.login}</td>
                </tr>
                <tr>
                  <td style={myStyle}>Password</td>
                  <td>{data?.password}</td>
                </tr>
                </tbody>
                <tfoot></tfoot>
              </table>
            :
            <DisplayLoader value={false}/>}
        </div>
    </div>
  )
}

export default OwnerPortal