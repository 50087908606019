import { isExpired } from "react-jwt";
import { userDataStorage } from "../config/constant";
import { customerKey } from "../config/RoleConfig";

export const saveUserSession = (obj) =>{
    try
    {
        const values = JSON.stringify(obj);
        localStorage.setItem(userDataStorage.key,values);
        return true;
    }
    catch(err)
    {
        console.log(err);
        return false;
    }
}

export const removeUserSession = () => {
    try
    {
        localStorage.removeItem(userDataStorage.key);
    }
    catch(err)
    {
        console.log(err);
    }
}

export const getUserSession = () =>{
    try
    {
        return JSON.parse(localStorage.getItem(userDataStorage.key));
    }
    catch(err)
    {
        console.log(err);
        return {};
    }
}

export const getToken = () =>{
    try
    {
        if(localStorage.getItem(userDataStorage.key))
        {
            const token = JSON.parse(localStorage.getItem(userDataStorage.key))?.token;
            return token;
        }
        else
        {
            return null;
        }
    }
    catch(err)
    {
        console.log(err);
        return null;
    }
}

export const isTokenExpired = () =>{
    try
    {
        return isExpired(getToken());
    }
    catch(err)
    {
        console.log(err);
        return false;
    }
}

export const shouldAllowedOfRoles = (roles_allowed_arr,roles_arr=[customerKey]) =>
{
    try
    {
        if(Array.isArray(roles_allowed_arr))
        {
            return roles_allowed_arr.some((r)=>r.includes(roles_arr));
        }
        else
        {
            return false;
        }
    }
    catch(err)
    {
        return false;
    }
}