import React,{useState,useEffect} from 'react'
import { GetAssistantJuniorEngineer, GetDeputyCommissioner, GetDeputyEngineer, GetDocumentUpload, GetEnagarPortal, GetFileDetails, GetPlotValidation, GetZonalOfficer } from '../../../config/api';
import { callApi } from '../../../services/ApiService';
import { AuthHeader, toastError } from '../../../services/CommonFunction';
import { useParams } from 'react-router';
import { DisplayLoader } from '../../../UtilsComponent/DisplayLoader';
import { GetDate } from '../../../services/DateAndTimeService';
import { urls,appUrl } from '../../../config/constant';

const Deputy_commissioner = () => {

  const params = useParams();

  const [loading,setLoading] = useState(false);

  const [data,setData] = useState();

  const fetchData =async () =>{
    try
    {
      setLoading(true);
      const res = await callApi(GetDeputyCommissioner.method,GetDeputyCommissioner.url,params?.id,null,AuthHeader());

      if(!res?.data?.error)
      {
        setLoading(false);
        const data1 = res?.data?.data1;
        const data2 = res?.data?.data2;

        if (!res.data?.maxcount) {
          setData({ remarks: "", date: "", documents: "" });
        }
        else {
          if (Array.isArray(data1) && data1?.length) {
            if (typeof data1[0]?.documents != "undefined" && data1[0]?.documents?.length > 0) {
              setData({ remarks: data1[0]?.remarks, date: data1[0]?.date ? GetDate(data1[0]?.date, "DD-MM-YYYY") : "", documents: data1[0]?.documents });
            } else {
              setData({ remarks: data1[0]?.remarks, date: data1[0]?.date ? GetDate(data1[0]?.date, "DD-MM-YYYY") : "", documents: "" });
            }
          }
          else {
            if (typeof data2[0]?.documents != "undefined" && data2[0]?.documents?.length > 0) {
              setData({ remarks: data2[0]?.remarks, date: data2[0]?.date ? GetDate(data2[0]?.date, "DD-MM-YYYY") : "", documents: data2[0]?.documents });
            } else {
              setData({ remarks: data2[0]?.remarks, date: data2[0]?.date ? GetDate(data2[0]?.date, "DD-MM-YYYY") : "", documents: "" });
            }
          }
        }
      }
      else
      {
        setLoading(false);
        toastError(res?.data?.message);
      }

    }
    catch(err)
    {
      setLoading(false);
      console.log(err);
      toastError(err?.res?.data?.message);
    }
  }

  const myStyle = {
    width: "20%"
  }

  useEffect(()=>{
    fetchData();
  },[]);

  return (
    <div>
        <div className="content_box_header">
            <h2>Deputy Commissioner</h2>
        </div>
        <div className="content_box_body">
        {!loading ?
          <table className='table'>
            <thead></thead>
            <tbody>
              <tr>
                <td style={myStyle}>Query</td>
                <td>{data?.remarks}</td>
              </tr>
              <tr>
                <td style={myStyle}>Date</td>
                <td>{data?.date}</td>
              </tr>
              <tr>
                <td style={myStyle}>File</td>
                <td>{data?.documents ? <a href={`${urls.dir_url}/${data?.documents}`} target={"_blank"}><img src={`${appUrl}/assets/svgs/down_arrow.svg`}/></a> : ""}</td>
              </tr>
            </tbody>
            <tfoot></tfoot>
          </table>
          :
          <DisplayLoader value={false} />}
        </div>
    </div>
  )
}

export default Deputy_commissioner