import React,{useEffect,useCallback, useState} from 'react'
import { appUrl } from '../../config/constant'
import { Link } from 'react-router-dom'
import { FileRoutes } from '../../config/RouteConfig'
import { getUserFullName } from '../../services/UserData'
import { AuthHeader, toastError, toastSuccess } from '../../services/CommonFunction'
import { callApi } from '../../services/ApiService'
import { GetFilesApi } from '../../config/api'
import { DisplayLoader } from '../../UtilsComponent/DisplayLoader'

const FileList = () => {

   // borderBottom:"0.2px solid #21232229"

   const [files,setFiles] = useState([]);
   const [loading,setLoading] = useState(false);

   const fetchData = async () =>{
      try
      {
         setLoading(true);

         const res = await callApi(GetFilesApi.method,GetFilesApi.url,null,null,AuthHeader());

         if(!res?.data?.error)
         {
            setLoading(false);
            const payload = res?.data?.data;
            console.log(payload);
            if(Array.isArray(payload))
            {
               setFiles(payload);
            }
         }  
         else
         {
            setLoading(false);
         }

      }
      catch(err)
      {
         setLoading(false);
         console.log(err);
         // toastError("Something went wrong");
      }
   }

   useEffect(()=>{
      console.log("called again");
      fetchData();
   },[]);

  


  return ( <React.Fragment>

      {!loading ? 

      <div className='row'>
      {files?.length>0  ?
         <React.Fragment>
         {files.map((f,i)=>(
            
           
      <div className="col-lg-4 mb-3">
          <Link to={`/${FileRoutes.file_details}/${f?.id}`} style={{color:"black",textDecoration:"none"}}>
            <div className="welcome_box">
               <div className='d-flex justify-content-between align-items-center' style={{padding:"12px",}}>
               <div className="box_header">
                  <h4 className="box_heading">{f?.file_name}</h4>
                  <p className="tps_text">{f?.office_file_no}</p>
               </div>
               <div>
                  <p className='tps_text'>{f?.code_title}</p>
               </div>
               </div>
               <div className="box_footer">
                  <div className="left_part">
                     <span className="stage">TP Stage</span>
                     <span className="status">Status</span>
                     <div className="date d-flex align-items-center">
                        <img src={`${appUrl}/assets/images/calendar.png`} className="d-inline-block"/>
                        <span>24 Feb,2022</span>
                     </div>
                  </div>
                  <div className="right_part">
                     <span className="final">{f?.tps_stage==1 ? "Draft" : f?.tps_stage==2 ? "Final" : <>&nbsp;</>}</span>
                        <span className="portal">{(f?.stage_title_id==2 || f?.stage_title_id==3) ? "File Details" : f?.stage_title}</span>
                     <div className="down_arrow_btn"><i className="fa-solid fa-arrow-down"></i></div>
                  </div>
               </div>
            </div>
            </Link>
         </div>
         

         
         ))}

   </React.Fragment>

      :""}
      </div>

      :
      <DisplayLoader value={false}/>
   }

</React.Fragment>
  
  )
}

export default FileList;
