import React,{useState,useEffect} from 'react'
import { GetDocumentUpload, GetEnagarPortal, GetFileDetails, GetPlotValidation } from '../../../config/api';
import { callApi } from '../../../services/ApiService';
import { AuthHeader, toastError } from '../../../services/CommonFunction';
import { useParams } from 'react-router';
import { DisplayLoader } from '../../../UtilsComponent/DisplayLoader';
import { urls,appUrl } from '../../../config/constant';


const DocumentUpload = () => {

  const params = useParams();

  const [loading,setLoading] = useState(false);
  const [data,setData] = useState([]);

  const fetchData =async () =>{
    try
    {
      setLoading(true);
      const res = await callApi(GetDocumentUpload.method,GetDocumentUpload.url,params?.id,null,AuthHeader());

      if(!res?.data?.error)
      {
        setLoading(false);
        if(Array.isArray(res?.data?.data))
        {
          setData(res?.data?.data);
        }
      }
      else
      {
        setLoading(false);
        toastError(res?.data?.message);
      }

    }
    catch(err)
    {
      setLoading(false);

      console.log(err);
      toastError(err?.res?.data?.message);
    }
  }

  useEffect(()=>{
    fetchData();
  },[]);

  const myStyle = {
    width : "20%"
  }

    const downloadFile = (
    filePath,
    fileName = filePath,
  ) => {
    
    fetch('https://webclient.zaidexceldesign.com:3001/api/'+filePath, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
    }

  return (
    <div>
        <div className="content_box_header">
            <h2>Document Upload</h2>
        </div>
        <div className="content_box_body">
          {!loading ? 
          <table className='table'>
            <thead>
              <tr>
                <th style={myStyle}>No.</th>
                <th>Document Name</th>
                <th>File</th>
              </tr>
            </thead>
            <tbody>
              {data.map((value,index)=>(
                <tr>
                  <td>{(index+1)}</td>
                  <td>{(value?.document_name)}</td>
                  <td>

                    {value?.document_name?.toUpperCase()=="Google Image Link"?.toUpperCase() && (value?.document_path?.length>0 && value?.document_path!=null) ?
                      <a href={`${value?.document_path}`} target={"_blank"}><img src={`${appUrl}/assets/svgs/brown_eye.svg`}/></a> 
                    :
                    (value?.document_path) ? 
                    <button onClick={() => downloadFile(value?.document_path)}><img src={`${appUrl}/assets/svgs/down_arrow.svg`}/></button> 
                        // <a href={`${urls.dir_url}/${value?.document_path}`} target={"_blank"}><img src={`${appUrl}/assets/svgs/down_arrow.svg`}/></a> 
                    : ""
                    }
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot></tfoot>
          </table>
          :<DisplayLoader value={false}/>}
        </div>
    </div>
  )
}

export default DocumentUpload