const { urls } = require("./constant");

const URL = urls.backend_url;
module.exports = 
{
    LoginApi:{
        method:"POST",
        url:`${URL}/login`
    },
    GetFilesApi:{
        method:"GET",
        url:`${URL}/get-files`
    },
    GetFilesOfClientByIdApi:{
        method:"GET",
        url:`${URL}/get-files-byid`
    },
    GetFileStagesApi:{
        method:"GET",
        url:`${URL}/get-files-stage`
    },
    GetFileDetails:{
        method:"GET",
        url:`${URL}/get-file_details`
    },
    GetRequiredDocuments:{
        method:"GET",
        url:`${URL}/get-requiredDocument`
    },
    GetSendMessageToClient:{
        method:"GET",
        url:`${URL}/get-send_msg_client`
    },
    GetPlotValidation:{
        method:"GET",
        url:`${URL}/get-plotvalidation`
    },
    GetEnagarPortal:{
        method:"GET",
        url:`${URL}/get-enagarportal`
    },
    GetOwnerPortal:{
        method:"GET",
        url:`${URL}/get-ownerportal`
    },
    GetDocumentUpload:{
        method:"GET",
        url:`${URL}/get-document_upload`
    },
    GetRoadDeposit:{
        method:"GET",
        url:`${URL}/get-road_deposit`
    },
    GetAssistantJuniorEngineer:{
        method:"GET",
        url:`${URL}/get-assistant_junior_engineer`
    },
    GetDeputyEngineer:{
        method:"GET",
        url:`${URL}/get-deputy_engineer`
    },
    GetZonalOfficer:{
        method:"GET",
        url:`${URL}/get-zonal_officer`
    },
    GetDeputyCommissioner:{
        method:"GET",
        url:`${URL}/get-deputy_commissioner`
    },
    GetCommissioner:{
        method:"GET",
        url:`${URL}/get-commissioner`
    },
    GetAllImportantFees:{
        method:"GET",
        url:`${URL}/get-all_important_fees`
    },
    GetFSIC_Aminities:{
        method:"GET",
        url:`${URL}/get-fs_ic_amineties_fees`
    },
    GetWorkFree:{
        method:"GET",
        url:`${URL}/get-work_free`
    },
    GetApprovedPlan:{
        method:"GET",
        url:`${URL}/get-approved_plan`
    },


    GetProjectsOfClients:{
        method :"GET",
        url:`${URL}/get-projects-of-client`
    },
    GetSiteCondition:{
        method :"GET",
        url:`${URL}/get-site_condition`
    },
    GetTentativePdf:{
        method :"GET",
        url:`${URL}/get-tentative_pdf`
    },
    GetApprovedPlanPdf:{
        method :"GET",
        url:`${URL}/get-approved_plan-pdf`
    },
    GetBuildingBunglow:{
        method :"GET",
        url:`${URL}/get-building_bungalow`
    },
    GetInaugurationMessage:{
        method :"GET",
        url:`${URL}/get-inauguration_message`
    },

    GetProjectDirection:{
        method :"GET",
        url:`${URL}/get-project-direction`
    },

    GetProjectTypes:{
        method :"GET",
        url:`${URL}/get-project_types`
    }
  
}