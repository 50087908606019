import React,{useEffect,useCallback, useState} from 'react'
import { getUserFullName } from '../../services/UserData'
import { Link } from 'react-router-dom'
import { FileRoutes, ProjectRoutes } from '../../config/RouteConfig'
import classNames from "classnames"

const Home = ({children}) => {
  return (
    <section>
    <div className="container">
       <div className="row">
          <div className="heading_section text-center">
             <h4 className="welcome_heading">Welcome</h4>
             <h2>{getUserFullName()}</h2>
          </div>
          <div className="btn_section text-center">
             <Link to={`/${FileRoutes.previewFile}`} className={classNames("btn",{active:window.location.pathname.split("/")[1]==FileRoutes.previewFile})}>File</Link>
             <Link to={`/${ProjectRoutes.previewProject}`}className={classNames("btn",{active:window.location.pathname.split("/")[1]==ProjectRoutes.previewProject})}>Projects</Link>
             {/* <button className="btn active">File</button>
             <button className="btn" disabled={true}>Project</button> */}
          </div>
       </div>

        <React.Fragment>
            {children}
        </React.Fragment>
       
    </div>
 </section>
  )
}

export default Home