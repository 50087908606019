import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { DisplayLoader } from '../../../UtilsComponent/DisplayLoader'
import { AuthHeader, toastError, validateJsonString } from '../../../services/CommonFunction';
import { callApi } from '../../../services/ApiService';
import { GetInaugurationMessage } from '../../../config/api';
import { GetDate } from '../../../services/DateAndTimeService';
import { appUrl, urls } from '../../../config/constant';

const InaugurationMessage = () => {

    const params = useParams();

    const [inaugurationDate,setInaugurationDate] = useState();
    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(false);

    const [created_at,set_created_at] = useState("");


    const fetchData = async () => {
        try {
            setLoading(true);
            const res = await callApi(GetInaugurationMessage.method, GetInaugurationMessage.url, params?.id, null, AuthHeader());

            if (!res?.data?.error) 
            {
                setLoading(false);

                const payload = res?.data?.data;

                setInaugurationDate(payload[0]?.inauguration_date);

                set_created_at(payload[0]?.created_at_inauguration_message);

                const obj = validateJsonString(payload[0]?.inauguration_message);

                if(obj!=null)
                {
                    setData(obj);
                }

            }
            else 
            {
                setLoading(false);
                toastError(res?.data?.message);
            }

        }
        catch (err) {
            setLoading(false);
            console.log(err);
            toastError(err?.res?.data?.message);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

  return (
    <div>
            <div className="content_box_header">
                <h2>Inauguration Message</h2>
            </div>
            <div className="content_box_body">

            <div style={{textAlign:'start'}}>
                    {created_at ? <React.Fragment> <b>Date :  {GetDate(created_at,"DD-MMM-YYYY")}</b><hr/></React.Fragment> : ""}
                </div>

                {!loading ?
                    <React.Fragment>
                        <table className='table'>
                            <thead></thead>
                            <tbody>
                                <tr>
                                    <td>Inauguration Date</td>
                                    <td>{inaugurationDate ? GetDate(inaugurationDate,"DD-MMM-YYYY") : ""}</td>
                                </tr>
                                {
                                    Array.isArray(data) ? 
                                        <React.Fragment>
                                            {data.map((e,i)=>(
                                                <tr>
                                                    <td>Inauguration Image [{i+1}]</td>
                                                    <td>{e?.file?.length>0 ? <a href={`${urls.dir_url}/${e?.file}`} target={"_blank"}><img src={`${appUrl}/assets/svgs/down_arrow.svg`}/></a> : ""}</td>
                                                </tr>
                                            ))}
                                        </React.Fragment>
                                    :
                                    ""
                                }
                            </tbody>
                            <tfoot></tfoot>
                        </table>
                    </React.Fragment>
                    :
                    <DisplayLoader value={false} />
                }
            </div>
        </div>
  )
}

export default InaugurationMessage