import React from 'react'
import {useLocation,Navigate,Outlet} from 'react-router-dom';
import { getRole } from '../services/UserData';
import { getToken, shouldAllowedOfRoles } from '../services/AuthService';
import { LoginRoute } from '../config/RouteConfig';

const RequireAuth = (props) => {

  const location = useLocation();
  const {accessible} = props;

  let isAuthentic = false;

  if(!getToken()){
    isAuthentic = false;
  }else {
    isAuthentic = shouldAllowedOfRoles(getRole(),accessible);
  }

  return (
    <React.Fragment>
      {!isAuthentic ? <Navigate to={`/${LoginRoute.preview}`} state={{from:location}} replace/> : <Outlet/> }
    </React.Fragment>
  )
}

export default RequireAuth