import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { DisplayLoader } from '../../../UtilsComponent/DisplayLoader'
import { callApi } from '../../../services/ApiService';
import { AuthHeader, toastError, validateJsonString } from '../../../services/CommonFunction';
import { GetProjectDirection, GetSiteCondition } from '../../../config/api';
import { appUrl, urls } from '../../../config/constant';
import { GetDate } from '../../../services/DateAndTimeService';


const SiteCondition = () => {

    const params = useParams();

    const [data,setData] = useState();
    const [loading,setLoading] = useState(false);
    const [created_at,set_created_at] = useState("");
    const [projectDirection,setProjectDirection] = useState([]);

    const fetchProjectDirection = async () =>{
        try
        {
            setLoading(true);
            const res = await callApi(GetProjectDirection.method, GetProjectDirection.url,null , null, AuthHeader());

            if(!res?.data?.error)
            {
                setLoading(false);
                setProjectDirection(res?.data?.data);
            }
            else
            {
                setLoading(false);
            }
            
        }
        catch(err)
        {
            setLoading(false);
            console.log(err);
        }
    }

    const fetchData = async () => {
        try {
            setLoading(true);
            const res = await callApi(GetSiteCondition.method, GetSiteCondition.url, params?.id, null, AuthHeader());

            if (!res?.data?.error) 
            {
                setLoading(false);
                const payload = res?.data?.data;

                set_created_at(payload[0]?.created_at_site_condition);

                console.log(payload);

                const obj = validateJsonString(payload[0]?.site_condition);

                console.log(obj);

                if(obj!=null){
                    setData(obj);
                }
            }
            else 
            {
                setLoading(false);
                toastError(res?.data?.message);
            }

        }
        catch (err) {
            setLoading(false);
            console.log(err);
            toastError(err?.res?.data?.message);
        }
    }

    const getTypeName = (id) =>{
        try{
            let typeName = "";
            for(let e of projectDirection)
            {
                if(e?.id==id)
                {
                    typeName = e?.type_name;
                    break;
                }
            }
            return typeName;
        }catch(err){
            console.log(err);
            return "";
        }
    }

    useEffect(() => {
        fetchProjectDirection();
        fetchData();
    }, []);

    return (
        <div>
            <div className="content_box_header">
                <h2>Site Condition</h2>
            </div>
           
            <div className="content_box_body">

                <div style={{textAlign:'start'}}>
                    {created_at ? <React.Fragment> <b>Date :  {GetDate(created_at,"DD-MMM-YYYY")}</b><hr/></React.Fragment> : ""}
                </div>

                


                {!loading ? 
                    <React.Fragment>

                        <table className='table'>
                            <thead></thead>
                            <tbody>
                                <tr>
                                    <td>East</td>
                                    <th>{data?.east ? getTypeName(data?.east) : ""}</th>
                                </tr>
                                <tr>
                                    <td>East Wide</td>
                                    <th>{data?.east_wide}</th>
                                </tr>
                                <tr>
                                    <td>West</td>
                                    <th>{data?.west ? getTypeName(data?.west) : ""}</th>
                                </tr>
                                <tr>
                                    <td>West Wide</td>
                                    <th>{data?.west_wide}</th>
                                </tr>
                                <tr>
                                    <td>North</td>
                                    <th>{data?.north ? getTypeName(data?.north) : ""}</th>
                                </tr>
                                <tr>
                                    <td>North Wide</td>
                                    <th>{data?.north_wide}</th>
                                </tr>
                                <tr>
                                    <td>South</td>
                                    <th>{data?.south ? getTypeName(data?.south) : ""}</th>
                                </tr>
                                <tr>
                                    <td>South Wide</td>
                                    <th>{data?.south_wide}</th>
                                </tr>
                                <tr>
                                    <td>Google Image 1</td>
                                    <td>{data?.google_image_1?.length>0 ? <a href={`${urls.dir_url}/${data?.google_image_1}`} target={"_blank"}><img src={`${appUrl}/assets/svgs/down_arrow.svg`}/></a> : ""}</td>
                                </tr>
                                <tr>
                                    <td>Google Image 2</td>
                                    <td>{data?.google_image_2?.length>0 ? <a href={`${urls.dir_url}/${data?.google_image_2}`} target={"_blank"}><img src={`${appUrl}/assets/svgs/down_arrow.svg`}/></a> : ""}</td>
                                </tr>
                               
                                {Array.isArray(data?.site_condition_image) ? 
                                    <React.Fragment>
                                        {data?.site_condition_image?.map((e,i)=>(
                                            <tr>
                                                <td>Site Condition Image {(i+1)}</td>
                                                <td>{e?.file?.length>0 ? <a href={`${urls.dir_url}/${e?.file}`} target={"_blank"}><img src={`${appUrl}/assets/svgs/down_arrow.svg`}/></a> : ""}</td>
                                            </tr>
                                        ))}
                                    </React.Fragment>


                                :""}
                                
                            </tbody>
                            <tfoot></tfoot>
                        </table>

                    </React.Fragment>
                :
                <DisplayLoader value={false}/>
                }
            </div>
        </div>
    )
}

export default SiteCondition