import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { DisplayLoader } from '../../../UtilsComponent/DisplayLoader'
import { AuthHeader, toastError, validateJsonString } from '../../../services/CommonFunction';
import { callApi } from '../../../services/ApiService';
import { GetBuildingBunglow, GetProjectTypes } from '../../../config/api';
import { GetDate } from '../../../services/DateAndTimeService';


const BuildingBungalowDetails = () => {

    const params = useParams();

    const [building_project_type,set_building_project_type] = useState();
    const [project_types,set_project_types] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [created_at,set_created_at] = useState("");


    const fetchProjectTypes = async () => {
        try {
            setLoading(true);
            const res = await callApi(GetProjectTypes.method, GetProjectTypes.url, null, null, AuthHeader());

            if (!res?.data?.error) 
            {
                setLoading(false);
                const payload = res.data?.data;
                
                set_project_types(payload);
            }
            else 
            {
                setLoading(false);
            }

        }
        catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    const fetchData = async () => {
        try {
            setLoading(true);
            const res = await callApi(GetBuildingBunglow.method, GetBuildingBunglow.url, params?.id, null, AuthHeader());

            if (!res?.data?.error) 
            {
                setLoading(false);
                const payload = res.data?.data;
                set_created_at(payload[0]?.created_at_building_details);
                set_building_project_type(payload[0]?.building_project_type);

                const obj = validateJsonString(payload[0]?.building_details);

                if(obj!=null){
                    setData(obj);
                }
            }
            else 
            {
                setLoading(false);
                toastError(res?.data?.message);
            }

        }
        catch (err) {
            setLoading(false);
            console.log(err);
            toastError(err?.res?.data?.message);
        }
    }

    const getProjectTypeName = (id) =>{
        try
        {
            let typeName = "";

            for(let e of project_types)
            {
                if(e?.id==id)
                {
                    return e?.type_name
                }
            }

            return typeName;
        }
        catch(err)
        {
            console.log(err);
            return "";
        }
    }

    useEffect(() => {
        fetchProjectTypes();
        fetchData();
    }, []);

  return (
    <div>
            <div className="content_box_header">
                <h2>Building / Bungalow Details</h2>
            </div>
            <div className="content_box_body">

                <div style={{textAlign:'start'}}>
                    {created_at ? <React.Fragment> <b>Date :  {GetDate(created_at,"DD-MMM-YYYY")}</b><hr/></React.Fragment> : ""}
                </div>

                {!loading ?
                    <React.Fragment>
                        <table className='table'>
                            <thead></thead>
                            <tbody>
                            <tr>
                                <td>Project Type</td>
                                <td>{building_project_type ? getProjectTypeName(building_project_type) : ""}</td>
                            </tr>
                            {
                                Array.isArray(data) ? 

                                    <React.Fragment>

                                        {data.map((e,i)=>(
                                            <tr>
                                                <td>Building Name : {e?.building_name}</td>
                                                <td>Total No. of Floor : {e?.num_of_floor}</td>
                                            </tr>
                                        ))}

                                    </React.Fragment>

                                :""
                            }
                            </tbody>
                            <tfoot></tfoot>
                        </table>
                    </React.Fragment>
                    :
                    <DisplayLoader value={false} />
                }
            </div>
        </div>
  )
}

export default BuildingBungalowDetails