import React from 'react'
import { appUrl } from '../config/constant'
import { ReactSVG } from 'react-svg'
import {useNavigate,Link} from "react-router-dom";
import { getToken, removeUserSession } from '../services/AuthService';
import { FileRoutes, LoginRoute, LogoutRoute } from '../config/RouteConfig';

const Header = () => {
   const navigate = useNavigate();

   const doLogout = async () =>{
      removeUserSession();
      // navigate(`/${LoginRoute.preview}`,{});
      window.location.href=LogoutRoute.preview;
   }

  return (
   <header className="header">
         <div className="container">
            <nav className="navbar navbar-expand-lg ">
               {/* <a className="navbar-brand" href="#"><img src="images/zaid_logo1.png" className="header_logo"></a> */}
               <a href="https://www.zaidexceldesign.com"><ReactSVG src={`${appUrl}/assets/svgs/logo.svg`} className="header_logo"/></a>
               <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <i className="fa-solid fa-bars"></i>
               </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav">
                     {/* <li className="nav-item">
                        <a className="nav-link" href="#">Work</a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link" href="#">Blogs</a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link" href="#">Process</a>
                     </li> */}

                     {window.location.pathname.split("/")[1]=="Files" && window.location.pathname.split("/")[2]=="details"
                                          ?
                     <li className="nav-item">
                        <Link className="nav-link main_btn" to={`/${FileRoutes.previewFile}`}>My Files</Link>
                     </li>
                     :""}
                     {getToken() ?
                     <li className="nav-item">
                        <a className="nav-link main_btn" role={"button"} onClick={doLogout}>Logout</a>
                     </li>
                     :""}
                     
                  </ul>
               </div>
            </nav>
         </div>
      </header>

  )
}

export default Header