import React from 'react'
import Header from './Header'
import {Toaster}  from 'react-hot-toast';

const Wrapper = ({children}) => {
  return (
    <React.Fragment>
      <Toaster
                position="top-right"
                reverseOrder={false}
            />
        <Header/>
        {children}
    </React.Fragment>
  )
}

export default Wrapper