import React,{useEffect,useCallback, useState} from 'react'
import { Link,useParams } from 'react-router-dom'
import { ProjectRoutes } from '../../config/RouteConfig'
import classNames from "classnames"
import { callApi } from '../../services/ApiService'
import { GetFilesOfClientByIdApi, GetFileStagesApi } from '../../config/api'
import { AuthHeader, toastError, toastSuccess } from '../../services/CommonFunction'
import FileDetail from './ProjectDetails/FileDetails'
import SiteCondition from './ProjectDetails/SiteCondition'
import TentativePdf from './ProjectDetails/TentativePdf'
import ApprovedPlanPdf from './ProjectDetails/ApprovedPlanPdf'
import BuildingBungalowDetails from './ProjectDetails/BuildingBungalowDetails'
import InaugurationMessage from './ProjectDetails/InaugurationMessage'

//Projects
const Details = () => {

    const params = useParams();
    // const [stageList,setStageList] = useState([]);
    const stageList = [
        {id:1,title:"File Details"},
        {id:2,title:"Site Condition"},
        {id:3,title:"Tentative [PDF]"},
        {id:4,title:"Approved Plan [PDF]"},
        {id:5,title:"Building / Bungalow Details"},
        {id:6,title:"Inauguration Messages"},
      ];
    const [currentState,setCurrentState] = useState(1);
    const [lastStage,setLastStage] = useState(1);
    const [file,setFile] = useState();
    const [loading,setLoading] = useState(false);

    const fetchFile = useCallback(async ()=>{
        try
        {
           const res = await callApi(GetFilesOfClientByIdApi.method,GetFilesOfClientByIdApi.url,params?.id,null,AuthHeader());
  
           if(!res?.data?.error)
           {
              if(Array.isArray(res?.data?.data))
              {
                setFile(res?.data?.data[0]);
              }
           }
          
        }
        catch(err)
        {
           console.log(err);
           toastError("Something went wrong");
        }
     },[]);

     const renderComponent = (num) =>{
        setCurrentState(num);
     }

    
 
     useEffect(()=>{
        fetchFile();
     },[]);
  

  return (
    <React.Fragment>
        <section className="breadcrumb_section">
         <div className="container">
            <nav aria-label="breadcrumb">
               <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to={`/${ProjectRoutes.previewProject}`} style={{color:"black"}}>Home</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">Project Details</li>
               </ol>
            </nav>

         </div>
        
         <section className="amalgamation">
         <div className="container">
            <div className="amalgamation_heading">
               <h2>{file?.file_name}</h2>
               <h5>{file?.office_file_no}</h5>
            </div>
            <div className="row">
               <div className="col-xl-4 col-md-4 col-12">
                  <div className="sidebar nav nav-tabs" id="myTab" role="tablist">
                     {stageList?.length>0 ?
                     <React.Fragment>
                        {stageList.map((s,i)=>(
                            <p onClick={()=>renderComponent(s?.id)} className={classNames(`amalgamation_sidebar_link`,{"active":s?.id==(currentState)})} type="button" role="tab" ><span className="number">{i+1}</span><span className="link_text">{s?.title}</span></p>
                        ))}
                     </React.Fragment>
                     :""}
                  </div>
               </div>
               <div className="col-xl-8 col-md-8 col-12">
                  <div className="content_box">
                  <div className="tab-content" id="myTabContent">
                    {(()=>{
                      switch(currentState)
                      {
                        case 1 : return (<FileDetail/>)
                        case 2 : return (<SiteCondition/>)
                        case 3 : return (<TentativePdf/>)
                        case 4 : return (<ApprovedPlanPdf/>)
                        case 5 : return (<BuildingBungalowDetails/>)
                        case 6 : return (<InaugurationMessage/>)
                        default : return (<FileDetail/>)
                      }
                  })()}
                  </div>

                     
                  </div>
               </div>
            </div>
         </div>
      </section>
        
       </section>
      
    </React.Fragment>
  )
}

export default Details